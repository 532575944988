import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";

const DocumentationPageStyles = styled.main`
  h1 {
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .documentation-section {
    padding: 1rem 2rem;
    width: 1000px;
    max-width: 95%;
    margin: 0 auto;

    h3 {
      margin: 1rem 0;
      padding-bottom: 1rem;
      border-bottom: 1px solid #000;
    }

    .content {
      padding: 1rem 0;

      li {
        height: fit-content;
        width: 100%;
        display: inline-block;

        a {
          display: block;
          color: #000;
          margin: 1rem 0;
          cursor: pointer;
          line-height: 1.25;
        }
      }
    }
  }
`

export default function DocumentationPage({ data, location }) {
  const siteTitle = "Documentation"
  const page = data.file.childMarkdownRemark.frontmatter
  const config = page.configuration

  return (
    <Layout location={location} title={config?.title || siteTitle}>
      <SEO
        title={config?.title || siteTitle}
        keywords={config?.keywords || []}
      />
      <DocumentationPageStyles>
        <h1>Documentation</h1>
        <div className="documentation-section documents">
          <h3>Documents</h3>
          <ul className="content">
            {page.documents.map((doc, index) => (
              <li key={index}>
                <a href={doc.file.publicURL} target="_blank">
                  {doc.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="documentation-section helpful-links">
          <h3>Helpful Links</h3>
          <ul className="content">
            {page.helpfulLinks.map((link, index) => (
              <li key={index}>
                <a href={link.link} target="_blank">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </DocumentationPageStyles>
    </Layout>
  );
}

export const query = graphql`
query DocumentationPageQuery {
  file(sourceInstanceName: { eq: "pages" }, name: { eq: "documentation" }) {
    name
    childMarkdownRemark {
      id
      html
      frontmatter {
        templateKey
        configuration {
          title
          keywords
        }
        documents {
          title
          file {
            publicURL
          }
        }
        helpfulLinks {
          title
          link
        }
      }
    }
  }
}
`;